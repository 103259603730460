import { useRef, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import TopNavBar from "../../../components/TopNavBar";
import "./CreatePolicy.css";
import { FileUpload } from "primereact/fileupload";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UploadIcon } from "../../../assets/svg/upload.svg";
import axios from "axios";
import { ToastContainer, toast, useToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToggleSwitch from "../../../components/UIComponent/ToggleSwitch";
import NavigateBack from "../../../utils/NavigateBack";

const CreatePolicy = () => {
  const [fileDetails, setFileDetails] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const [formDetails, setFormDetails] = useState({
    policyName: "",
    policyVersion: "",
    policyFormat: "",
    visibility: false,
  });
  const router = useNavigate();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileDetails(file);
    } else {
      setFileDetails(null);
    }
  };

  const handleInput = (e) => {
    setFormDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputClick = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleDropdownSelect = (value) => {
    setFormDetails((prev) => ({
      ...prev,
      policyFormat: value,
    }));
    setShowDropdown(false);
  };

  const handleVisibilityChange = (checked) => {
    console.log("checked", checked);
    setFormDetails((prev) => ({
      ...prev,
      visibility: checked,
    }));
  };

  function checkFileType(file) {
    const mimeType = file.type;

    switch (mimeType) {
      case "image/png":
        return "png";
      case "image/jpg":
        return "jpg";
      case "image/jpeg":
        return "jpeg";
      case "application/pdf":
        return "pdf";
      case "text/plain":
        return "txt";
      default:
        return "unknown";
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    const { policyName, policyVersion, policyFormat } = formDetails;
    if (!policyName || !policyVersion || !policyFormat || !fileDetails) {
      toast.error("Please fill in all required fields!");
      return;
    }

    const fileType = checkFileType(fileDetails);

    console.log("fileType", fileType, policyFormat.toLocaleLowerCase());
    if (fileType !== policyFormat.toLocaleLowerCase()) {
      toast.error("Please upload correct format");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("file", fileDetails);

      const fileUpload = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/files`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("fileUpload response", fileUpload);

      const updatedFormDetails = {
        ...formDetails,
        policyFile: fileUpload.data.body[0]?.filename,
      };

      console.log("updatedFormDetails", updatedFormDetails);

      const createPolicyResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/policy`,
        updatedFormDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("createPolicyResponse", createPolicyResponse);
      if (createPolicyResponse.status === 201) {
        toast.success("Policy has been successfully created!");
        setTimeout(() => {
          router("/policy");
        }, 3000);
      }
    } catch (error) {
      toast.error("Error creating Policy!", error?.message);
    }
  };

  return (
    <>
      <div className="navBarWrapper">
        <Sidebar />
        <div className="contentWrapper">
          <TopNavBar />

          <div className="createPolicyForm">
            <div className="svg-container">
              <NavigateBack
                fill={"#6663a6"}
                onClick={() => (window.location.href = "/policy")}
              />
            </div>
            <form onSubmit={submitHandler}>
              <div className="policyFields">
                <div className="policyField one">
                  <label htmlFor="" className="policyLabel">
                    Policy Name*
                  </label>
                  <input
                    onChange={handleInput}
                    type="text"
                    name="policyName"
                    id="polci"
                    placeholder="Policy Name"
                    value={formDetails?.policyName}
                  />
                </div>
                <div className="policyField one">
                  <label htmlFor="" className="policyLabel">
                    Policy Version*
                  </label>
                  <input
                    onChange={handleInput}
                    type="text"
                    name="policyVersion"
                    id="policyVersion"
                    placeholder="Version Name"
                    value={formDetails?.policyVersion}
                  />
                </div>
                <div
                  className="policyField one"
                  style={{ position: "relative" }}
                >
                  <label htmlFor="" className="policyLabel">
                    Policy Format*
                  </label>
                  <input
                    onChange={handleInput}
                    onClick={handleInputClick}
                    type="text"
                    name="policyFormat"
                    id="policyFormat"
                    placeholder="Policy File type"
                    value={formDetails?.policyFormat}
                    readOnly
                  />
                  {showDropdown && (
                    <div className="dropdown">
                      <div
                        className="dropDownoption"
                        onClick={() => handleDropdownSelect("JPG")}
                      >
                        JPG
                      </div>
                      <div
                        className="dropDownoption"
                        onClick={() => handleDropdownSelect("PDF")}
                      >
                        PDF
                      </div>
                      <div
                        className="dropDownoption"
                        onClick={() => handleDropdownSelect("PNG")}
                      >
                        PNG
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="policyField file"
                  style={{ position: "relative" }}
                >
                  <label htmlFor="" className="policyLabel">
                    Upload Policy*
                  </label>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept="image/png, image/jpeg, application/pdf"
                  />
                  <div className="uploadPolicy">
                    <span className="uploadButton">
                      <UploadIcon />
                      Upload
                    </span>
                  </div>
                  {fileDetails && (
                    <span className="uploaded-file-details">
                      {fileDetails?.name}
                    </span>
                  )}
                </div>
                <div className="visibilityToggle">
                  <label htmlFor="" className="policy_toggle_label">
                    Visibility
                  </label>
                  <div className="switchContainer">
                    <ToggleSwitch
                      defaultChecked={formDetails.visibility}
                      onChange={handleVisibilityChange}
                    />
                  </div>
                </div>
              </div>
              <button className="publishPolicy-btn">Publish</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePolicy;
