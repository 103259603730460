import axios from "axios";
import { fetchAllData } from "./fetchData";
const apiURL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");
const settingEndpoint = "/v1/settings";
const filesEndPoint = "/v1/files"


const searchSettings = (userId, data) => {

    const setting = data.filter((setting) => setting.userId == userId);

    return setting
}


// const createSettingBody = async (setting,selectedFile) =>{
//     try{
//         const token = localStorage.getItem("token");
//         const settingBody = new FormData();
//         settingBody.append("file",setting.file);

//         if(selectedFile && !(typeof(selectedFile) === "string")){
//             const imageFormData = new FormData();
//             imageFormData.append('file', selectedFile)

//             const imageUploadResponse = await axios.post(`${apiURL}/v1/files/`,
//             imageFormData,
//             {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'multipart/form-data'
//               },
//             });
//             console.log(imageUploadResponse.data.body[0]);
//             settingBody.delete('file');
//             const imageName = imageUploadResponse.data.body[0].filename;
//             settingBody.append("file",imageName)
//         }

//         settingBody.append("appname",setting.appname);
//         settingBody.append("quickbook",setting.quickbook);
//         settingBody.append("description",setting.description);

//         return settingBody
//     }catch(error){
//         console.log(error);
//     }
// }

const createSettingBody = async (setting, selectedFile) => {
    try {
        const settingBody = new FormData();

        // Check if selectedFile is provided and not a string (in case it's an existing image name)
        if (selectedFile && !(typeof (selectedFile) === "string")) {
            const imageFormData = new FormData();
            imageFormData.append('file', selectedFile);

            const token = localStorage.getItem("token");
            const imageUploadResponse = await axios.post(`${apiURL}/v1/files/`,
                imageFormData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                });
            const imageName = imageUploadResponse.data.body[0].filename;

            // Replace the old image value with the new one
            settingBody.append("image", imageName);
        } else if (setting.image) {
            settingBody.append("image", setting.image);
        }

        // Append other fields
        settingBody.append("appname", setting.appname || "");
        settingBody.append("quickbook", setting.quickbook || false);
        settingBody.append("description", setting.description || "");

        // Debugging output
        console.log("FormData contents:");
        for (let [key, value] of settingBody.entries()) {
            console.log(key, value);
        }

        return settingBody;
    } catch (error) {
        console.log("Error in createSettingBody:", error.message);
        return new FormData(); // Return an empty FormData on error
    }
}




const getSettingImage = async (imageName) => {

    try {
        const token = localStorage.getItem("token");
        const imageResponse = await axios.get(`${apiURL}/v1/files/${imageName}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: 'arraybuffer',
            })

        const blob = new Blob([imageResponse.data], { type: 'image/jpeg' })
        const url = URL.createObjectURL(blob)
        let imageUrl = url;

        return imageUrl;

    } catch (error) {
        console.error('Error fetching setting image:', error.message);
    }
}


const createSetting = async (setting, selectedFile) => {
    try {
        const token = localStorage.getItem("token");
        const settingBody = await createSettingBody(setting, selectedFile);

        const response = await axios.post(`${apiURL}${settingEndpoint}`,
            settingBody,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json",
                },
            })

        return response;
    } catch (error) {
        console.log(`Error creating setting ${error.message}`);
    }
}

const updateSetting = async (id, setting, selectedFile) => {
    try {
        const token = localStorage.getItem("token");

        // Check if a file is selected and upload it
        if (selectedFile) {
            const formData = new FormData();
            formData.append("file", selectedFile);

            // Upload the file to the file API
            const fileResponse = await axios.post(
                `${apiURL}${filesEndPoint}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            // Extract the filename from the file upload response
            const { filename } = fileResponse.data.body[0];
            console.log("Uploaded file filename:", filename);

            // Construct the image URL
            const imageUrl = filename;
            console.log("Constructed imageUrl:", filename);

            // Set the image field in the setting object with the constructed imageUrl
            setting.image = imageUrl;
        }

        console.log("Settings data after file upload and image retrieval:", setting);

        // Update the setting with the processed image URL
        const response = await axios.patch(
            `${apiURL}${settingEndpoint}/${id}`,
            setting,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log("Settings update response:", response.data);
    } catch (error) {
        console.log(`Error updating setting: ${error.message}`);
    }
};





async function getUserSpecificSettings() {
    try {
        const token = localStorage.getItem("token");
        const response = await fetchAllData(settingEndpoint);

        const { data, status } = response
        console.log("Data", response)

        if (status === 200) {

            // const [header, payload, signature] = token.split(".");

            // // Decode the Base64-encoded payload
            // const decodedPayload = JSON.parse(atob(payload));

            // // Extract the user ID
            // const userId = decodedPayload.id;

            const user = localStorage.getItem("userData")
            const parsedUser = JSON.parse(user)


            console.log(data)
            if (data?.length) {
                let setting = searchSettings(parsedUser?.id, data);
                let settingImage = setting[0].image;

                if (settingImage) {
                    const imageUrl = await getSettingImage(settingImage);
                    setting[0].imageUrl = imageUrl;
                }

                return setting;
            }

            else {
                return data
            }



        }
    } catch (error) {
        console.error('Error fetching user settings:', error);
    }
}

export { getUserSpecificSettings, createSetting, updateSetting };