import React, { useEffect, useState } from "react";
import "./UserRequests.css";
import Sidebar from "../../components/Sidebar";
import TopNavBar from "../../components/TopNavBar";
import { useQuery } from "react-query";
import { fetchAllData } from "../../utils/fetchData";
import LoadingOverlay from "react-loading-overlay-ts";
import axios from "axios";
import Pagination from "../../components/navbarwidgets/Pagination";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem'; 
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
const apiURL = process.env.REACT_APP_API_URL;

function UserRequests() {
  
  const [usersData, setUsersData] = useState([]);
  const [isResponseOk, setIsResponseOk] = useState({});
  const userDataEndpoint = "/v1/users/allusers";
  
  const { data, isLoading } = useQuery(
    ["userReqData", userDataEndpoint],
    () => fetchAllData(userDataEndpoint),
    {
      onSuccess: (data) => {
        setUsersData(data.data);
      },
    }
  );
  console.log("user data list:", usersData);
  useEffect(() => {
    if (Array.isArray(data)) {
      const initialStatus = {};
      data.forEach((user) => {
        initialStatus[user.id] = false;
      });
      setIsResponseOk(initialStatus);
    }
  }, [data]);

  const handleReqToggle = async (email, userId, visibility) => {
    console.log("email", email);
    try {
      const token = localStorage.getItem("token");
      let response;
      if(visibility === 'inactive') {
        response = await axios.post(
        `${apiURL}/v1/auth/validate`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
    } else {
      response = await axios.post(
        `${apiURL}/v1/auth/invalidate`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
    }
      
      console.log("response is", response);
      setIsResponseOk((prevStatus) => ({
        ...prevStatus,
        [userId]: response.status === 200,
      }));
      window.location.reload();
    } catch (error) {
      console.log("error is", error);
      setIsResponseOk(false);
    }
  };
  const sortedUsersData = usersData.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  // pagination code
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const totalItems = usersData.length;

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const usersPaginationData = sortedUsersData.slice(startIndex, endIndex);
// 

  console.log("is reponse ok", isResponseOk);
  if (isLoading) {
    return (
      <>
        <LoadingOverlay
          active={isLoading}
          spinner
          text="Loading your content..."
        >
          <div className="navBarWrapper">
            <Sidebar />
            {/* <==> to homecontainer */}
            <div className="contentWrapper">
              <TopNavBar />
              {/* content of the page */}
              <div className="userApprovalWrapper">
                <p>Loading...</p>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </>
    );
  } else {
    if (usersData.length && data?.length) {
      setUsersData(data.data);
      <h2>No user requests</h2>;
    }
  }
  return (
    <>
      <div className="navBarWrapper">
        <Sidebar />
        {/* <==> to homecontainer */}
        <div className="contentWrapper">
          <TopNavBar />
          {/* content of the page */}  
          <div className="userApprovalWrapper">
            <table className="userApprovalTable">
              <thead>
                <tr>
                  <th>User name</th>
                  <th>Email</th>
                  <th>Phone No.</th>
                  <th>Membership</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {usersPaginationData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.firstname + " " + item.lastname}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{item.membership}</td>
                    <td>
                      <button
                        className={`defaultButton ${
                          item.visibility === "active" ? "acceptButton" : ""
                        }`}
                        onClick={() => handleReqToggle(item.email, item.id, item.visibility)}
                      >
                        {item.visibility === "active" ? "Reject" : "Accept"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {usersData.length > 6 && (
              <div className="pagWrapper btnAdjustClass">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                />
              </div>
            )}
          </div>
          {/* Mobile view code */}
          <div className="userApprovalMobWrapper">
            {usersPaginationData.map((userMob) => {
              return (
                <div className="mobUserApprovalData">
                  {/* <div className="userImage">
                    <img src={userMob.image} alt="" />
                  </div> */}
                  <div className="userData">
                    <p>
                      <span>Email:</span>
                      {userMob.email}
                    </p>
                    <p>
                      <span>Mobile number:</span> {userMob.phone}
                    </p>
                    <p>
                      <span>Membership:</span> {userMob.membership}
                    </p>
                    <button
                      className={`defaultButton ${
                        userMob.visibility === "active" ? "acceptButton" : ""
                      }`}
                      onClick={() => handleReqToggle(userMob.email, userMob.id)}
                    >
                      {userMob.visibility === "active" ? "Accepted" : "Accept"}
                    </button>
                  </div>
                </div>
              );
            })}
            {usersData.length > 6 && (
              <div className="pagWrapper btnAdjustClass">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UserRequests;
