import React, { useState, useRef, useContext } from "react";
import TopNavBar from "../../../components/TopNavBar";
import Sidebar from "../../../components/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  dragOverHandler,
  dropHandler,
} from "../../../utils/imageDragDropHandlers";
import { useQuery } from "react-query";
import { fetchFilters } from "../../../utils/filtersOperations";
import { ReactComponent as CloseIcon } from "../../../assets/svg/closeImg.svg";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import MyContext from "../../../utils/MyContext";
import { toast } from "react-toastify";
import { Asterisk, AsteriskText } from "../../../utils/Asterisk";
import NavigateBack from "../../../utils/NavigateBack";
const apiURL = process.env.REACT_APP_API_URL;

function EditCoach() {
  const filtersEndpoint = "skills";
  const location = useLocation();
  const [coachData, setCoachData] = useState(location.state);
  console.log(location.state.files);
  const [selectedFile, setSelectedFile] = useState(location.state.files);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [fileChange, setFileChange] = useState(false);
  const [skills, setSkills] = useState([]);
  const [newSkills, setNewSkills] = useState(coachData.skills);
  const animatedComponents = makeAnimated();
  const currentSkills = coachData.skills.split("|");

  const { data, isLoading } = useQuery(
    ["skillsFilters", filtersEndpoint],
    () => fetchFilters(filtersEndpoint),
    {
      onSuccess: (data) => {
        // setSkills(data.map((skills) => skills.title));
        setSkills(
          data.map((skill) => {
            return { value: skill.title, label: skill.title };
          })
        );
      },
    }
  );
  const handleOnInputChange = (event, additionalDetails) => {
    if (additionalDetails?.name === "skills") {
      const filters = event.map((facility) => facility.value);
      setNewSkills(filters.join("|"));
      return;
    }

    const { name, value } = event.target;
    setCoachData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleImageRemove = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setSelectedFile(file);
    setFileChange(true);
  };

  const handleCoachEdit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const imageFormData = new FormData();
      imageFormData.append("file", selectedFile);
      let imagePath;

      const _email = coachData?.email;
      const trimmedEmail = String(_email)?.trim();
      if (trimmedEmail.length === 0) {
        toast.warn("Please Enter Email");
        return;
      }

      if (fileChange && selectedFile) {
        const imageUploadResponse = await axios.Eventpost(
          `${apiURL}/v1/files/`,
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        imagePath = imageUploadResponse.data.body[0].filename;
        console.log("image upload status:", imageUploadResponse);
      }

      const formData = new FormData();
      console.log(coachData);
      formData.append("files", imagePath || coachData.files);
      formData.append("title", coachData.title);
      formData.append("skills", newSkills);
      formData.append("credits", coachData.credits);
      formData.append("description", coachData.description);
      formData.append("email", coachData.email);
      console.log(formData);
      const response = await axios.patch(
        `${apiURL}/v1/coaches/${coachData.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "application/json",
          },
        }
      );
      navigate("/coaches");
      window.location.reload();
      console.log("success:", response.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const { contextValue, setContextValue } = useContext(MyContext);

  return (
    <>
      {!isLoading && (
        <div className="navBarWrapper">
          <Sidebar />
          <div className="contentWrapper">
            <TopNavBar />
            {/* Content of the page */}
            <div className="addNewRoomWapper">\
          <NavigateBack fill={"#6663a6"} onClick={() => window.location.href="/coaches"}/>

              <div className="addNewFormWrapper">
                <form
                  action=""
                  className="addNewRoomForm"
                  onSubmit={handleCoachEdit}
                >
                  <h2>Edit Coach</h2>
                  <AsteriskText />
                  <div
                    className="fileInput"
                    onDragOver={dragOverHandler}
                    onDrop={(event) => {
                      dropHandler(event, setSelectedFile);
                      setFileChange(true);
                    }}
                  >
                    <label htmlFor="files">Select File</label>
                    <div className="fileNames" style={{ display: "flex" }}>
                      <p
                        className={
                          selectedFile ? "showFileDetails" : "hideFileDetails"
                        }
                      >
                        {selectedFile ? (
                          <>
                            {selectedFile.name || selectedFile}
                            <span
                              className="removeImage"
                              onClick={handleImageRemove}
                            >
                              <CloseIcon />
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                    <input
                      type="file"
                      name="files"
                      id="files"
                      style={{ display: "none" }}
                      accept="image/png, image/jpeg"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                    />
                  </div>
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="coachName">
                      <span>Email</span>
                      <span style={{ color: "red", paddingLeft: "4px" }}>
                        *
                      </span>
                    </label>
                    <br />
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter your Email"
                      onChange={handleOnInputChange}
                      required
                    />
                  </div>
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="coachName">
                      Coach Name
                      <Asterisk />
                    </label>
                    <br />
                    <input
                      type="text"
                      name="title"
                      placeholder="Enter Coach Name"
                      value={coachData.title}
                      onChange={handleOnInputChange}
                      required
                    />
                  </div>
                  <div className="addNewFormInputWrapper">
                    <label htmlFor="credits">
                      Credits
                      <Asterisk />
                    </label>
                    <br />
                    <input
                      type="text"
                      name="credits"
                      placeholder="Enter Credits"
                      value={coachData.credits}
                      onChange={handleOnInputChange}
                      onKeyDown={(e) => {
                        if (
                          !(
                            e.key === "Backspace" ||
                            e.key === "Delete" ||
                            e.key === "ArrowLeft" ||
                            e.key === "ArrowRight" ||
                            e.key === "Tab" ||
                            (e.key >= "0" && e.key <= "9")
                          )
                        ) {
                          e.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                  {/* multiple selection */}
                  {!isLoading && (
                    <div className="addNewFormInputWrapper">
                      <label htmlFor="skills">
                        Skills
                        <Asterisk />
                      </label>
                      <br />
                      {skills.length > 0 && (
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          defaultValue={skills.filter((skill, index) =>
                            currentSkills.includes(skill.value)
                          )}
                          options={skills}
                          // value={coachData.skills}
                          onChange={handleOnInputChange}
                          name="skills"
                          className={`react-select-container ${
                            contextValue ? "indexClass" : ""
                          }`}
                          classNamePrefix="react-select"
                          classNames={{
                            container: (state) => "react-select-container",
                          }}
                          required
                        />
                      )}
                      {/* {skills.map((skill) => (
                      <option value={skill}>{skill}</option>
                    ))}
                  </select> */}
                    </div>
                  )}

                  <div className="addNewFormInputWrapper descheight">
                    <label htmlFor="description">
                      Description
                      <Asterisk />
                    </label>
                    <br />
                    <textarea
                      type="text"
                      name="description"
                      placeholder="Enter Description"
                      value={coachData.description}
                      onChange={handleOnInputChange}
                      required
                    ></textarea>
                  </div>
                  <div className="addBtnWrapper">
                    <button type="submit">Add</button>
                  </div>
                </form>
              </div>
            </div>
            {/* Content of the page */}
          </div>
        </div>
      )}
    </>
  );
}

export default EditCoach;
